<template>
  <div
    class="breadcrumb csn-breadcrumb"
    :class="{
      'csn-breadcrumb-five': isCasinoFive,
    }"
  >
    <Loader v-if="isPending" />
    <div v-if="!isPending" class="breadcrumb-box">
      <div class="category">{{ t('category') }}</div>
      <div class="page-title">
        <ol class="breadcrumb-title">
          <li class="breadcrumb-item">
            <span>
              {{ categoryLabel }}
            </span>
          </li>
        </ol>
      </div>
    </div>
    <div v-if="!isPending" class="game-filters-box">
      <div class="row csn-game-short-button">
        <ul class="filters csn-breadcrumb-filters">
          <li class="csn-breadcrumb-lobby">
            <Route
              :to="{ name: routeName.freePlay }"
              :class="{
                'csn-breadcrumb-selected':
                  currentRouteName === routeName.freePlay,
              }"
            >
              <span class="casino-icon casino-icon-lobby"></span>
              <div>{{ t('play_now') }}</div>
            </Route>
          </li>
          <li class="csn-breadcrumb-new">
            <Route
              :to="{ name: routeName.newGames }"
              :class="{
                'csn-breadcrumb-selected':
                  currentRouteName === routeName.newGames,
              }"
            >
              <span class="casino-icon casino-icon-new-games"></span>
              <div>{{ t('new_games') }}</div>
            </Route>
          </li>
          <li class="csn-breadcrumb-top">
            <Route
              :to="{ name: routeName.topGames }"
              :class="{
                'csn-breadcrumb-selected':
                  currentRouteName === routeName.topGames,
              }"
            >
              <span class="casino-icon casino-icon-top-games"></span>
              <div>{{ t('top_games') }}</div>
            </Route>
          </li>
          <li
            v-for="{ id, name, urlSlug, image } in categoryList"
            :key="id"
            class="csn-breadcrumb-slot"
          >
            <Route
              :to="{ name: routeName.gameCategory, params: { slug: urlSlug } }"
              :class="{
                'csn-breadcrumb-selected':
                  currentRouteName === routeName.gameCategory &&
                  currentRouteSlug === urlSlug,
              }"
            >
              <img :src="image" class="casino-icon" />
              <div>{{ name }}</div>
            </Route>
          </li>

          <li class="csn-breadcrumb-search">
            <a
              @click="openSearchMenu"
              :class="{
                'csn-breadcrumb-selected': SHOWS_SEARCH_MENU,
              }"
            >
              <span class="casino-icon casino-icon-search"></span>
              <div>{{ t('search') }}</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  BREADCRUMBS,
  RouteName,
  Module,
  GAME_VENDOR_DICTIONARY,
  EMPTY_STRING,
  TOGGLE_SEARCH_MENU,
  TOGGLE_MAIN_MENU,
  SHOWS_MAIN_MENU,
  SHOWS_SEARCH_MENU,
  ResponseState,
  URL_SLUG,
  CASINO_FIVE,
} from '@/constants'
import { indexBy } from '@/helpers'
import { GameApi } from '@/api'

export default {
  name: BREADCRUMBS,
  data: () => ({
    isPending: false,
    categoryList: null,
    currentRouteSlug: null,
  }),
  components: {
    Route: () => import('@/components/Route'),
    Loader: () => import('@/components/Loader'),
  },
  computed: {
    isCasinoFive: () => process.env.VUE_APP_THEME === CASINO_FIVE,
    routeName: () => ({
      freePlay: RouteName.GAME_FREE_PLAY,
      gameCategory: RouteName.GAME_CATEGORY,
      newGames: RouteName.GAME_NEW_GAMES,
      topGames: RouteName.GAME_TOP_GAMES,
    }),
    t() {
      return this.$createComponentTranslator(BREADCRUMBS)
    },
    ...mapState(Module.GAME_VENDOR, [GAME_VENDOR_DICTIONARY]),
    ...mapState(Module.MAIN, [SHOWS_MAIN_MENU, SHOWS_SEARCH_MENU]),
    currentRouteName() {
      return this.$router.history.current.name || EMPTY_STRING
    },
    categoryLabel() {
      if (!this.currentRouteName || !this.categoryList) {
        return EMPTY_STRING
      }

      if (RouteName.GAME_VENDOR === this.currentRouteName) {
        return this.GAME_VENDOR_DICTIONARY?.[this.currentRouteSlug]?.name
      }

      if (RouteName.GAME_CATEGORY === this.currentRouteName) {
        return this.categoryList?.[this.currentRouteSlug]?.name
      }

      const categoryDictionaryByStaticPath = {
        [RouteName.HOME]: this.t('home'),
        [RouteName.GAME]: this.t('lobby'),
        [RouteName.GAME_FREE_PLAY]: this.t('play_now'),
        [RouteName.GAME_NEW_GAMES]: this.t('new_games'),
        [RouteName.GAME_TOP_GAMES]: this.t('top_games'),
        [RouteName.SEARCH_RESULT]: this.t('search_result'),
      }

      return categoryDictionaryByStaticPath[this.currentRouteName]
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, { TOGGLE_SEARCH_MENU, TOGGLE_MAIN_MENU }),

    openSearchMenu() {
      this.SHOWS_MAIN_MENU && this.TOGGLE_MAIN_MENU()
      this.TOGGLE_SEARCH_MENU(true)
    },
  },
  watch: {
    $route: {
      handler({ params }) {
        this.currentRouteSlug = params.slug || EMPTY_STRING
      },
      immediate: true,
    },
  },
  async mounted() {
    this.isPending = true

    try {
      const { state, data } = await GameApi.getGameCategoryList()

      if (state === ResponseState.ERROR) {
        return
      }

      this.categoryList = indexBy(URL_SLUG)(data)
    } catch (e) {
      console.error(e)
    }

    this.isPending = false
  },
}
</script>
